"use strict";

namespace homekit {
    type byte = number;
    type int  = number;

    export class Accessory {
        public static GetCode(version: byte, reserved: byte, category : int, protocols : int, code : int, id: string) : string
        {
            // ip lightbulb 11223344 HSPN, expects X-HM://00527A71SHSPN
            // let code : string = this.GetCode(0, 0, 5, 2, 11223344, 'HSPN');

            let n : bigint = BigInt(code);
            n |= (BigInt(version & 0x7) << 43n);
            n |= (BigInt(reserved & 0xF) << 39n);
            n |= (BigInt(category & 0xFF) << 31n);
            n |= (BigInt(protocols & 0x7FFFFFF) << 27n);

            let encoded = n.toString(36).toUpperCase();
            while (encoded.length < 9)
            {
                encoded = '0' + encoded;
            }
            return 'X-HM://' + encoded + id;
        }

    }

    export enum Protocol {
        NFC = 1, IP = 2, BTLE = 4,
    }

    export enum AccessoryType {
        Other = 1,
        Bridge = 2,
        Fan = 3,
        GarageDoorOpener = 4,
        Lightbulb = 5,
        DoorLock = 6,
        Outlet = 7,
        Switch = 8,
        Thermostat = 9,
        Sensor = 10,
        SecuritySystem = 11,
        Door = 12,
        Window = 13,
        WindowCovering = 14,
        ProgrammableSwitch = 15,
        RangeExtender = 16,
        IpCamera = 17,
        VideoDoorBell = 18,
        AirPurifier = 19,
        Heater = 20,
        AirConditioner = 21,
        Humidifier = 22,
        Dehumidifier = 23,
        AppleTv = 24,
        Speaker = 26,
        Airport = 27,
        Sprinkler = 28,
        Faucet = 29,
        ShowerHead = 30,
        Television = 31,
        TargetController = 32,
    }

}